@define-mixin btnHove $color: var(--color__text) {
  &:hover {
    background-color: $color;
    color: white;
  }
}
.btn {
  display: inline-block;
  padding: .6em 2.4em;
  border: 1px solid var(--color__text);
  text-transform: uppercase;
  font: 400 14px/1.2 'Montserrat', sans-serif;
  transition: background-color .3s linear;
  @mixin btnHove;

  &&_color_red {
    color: var(--color__red);
    border-color: var(--color__red);
    @mixin btnHove var(--color__red);
  }
}
