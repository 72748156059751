a.floating-button {
  position: fixed;
  width: 50px;
  height: 50px;
  line-height: 50px;
  bottom: 80px;
  right: 30px;
  font-size: 20px;
  background-color: rgba(220, 16, 16, .45);
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  z-index: 1000;
  animation: pulsess 3s infinite;

  &:hover {
    color: #fff;
    background-color: #25D366;
    animation-play-state: paused;
  }

  &_phone {
    bottom: 160px;
    background-color: #25D366;

    &:hover {
      background-color: rgba(220, 16, 16, .45);
    }
  }
}

@keyframes pulsess {
  0% {
    box-shadow: 0 0 0 0 rgba(0,0,0, .3);
  }
  30% {
    box-shadow: 0 0 0 10px rgba(0,0,0, 0.1);
  }
  50% {
    box-shadow: 0 0 0 0 rgba(0,0,0, 0);
  }
  80% {
    box-shadow: 0 0 0 10px rgba(0,0,0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0,0,0, 0);
  }
}
