:root {
  --color__red: #ef0303;
  --color__gray: #e6e7e8;
  --color__text: #58595b;

  --zIndex__top: 9999;
  --zIndex__middle: 999;
  --zIndex__bottom: 99;
}
@define-mixin bevelSpace {
  position: relative;
  z-index: var(--zIndex__bottom);
  padding: 10% 0 !important;
}
@define-mixin footerBgAndShape {
  position: relative;
  z-index: var(--zIndex__bottom);
  background: url(../images/bg_1.jpg) center/cover no-repeat;
  clip-path: polygon(0 0, 100% 35%, 100% 100%, 0 100%);
  @media (--ipad){
    clip-path: polygon(0 0, 100% 13%, 100% 100%, 0 100%);
  }
  @media (--ipad_mini){
    clip-path: polygon(0 0, 100% 13%, 100% 100%, 0 100%);
  }
  @media (--phone){
    clip-path: polygon(0 0, 100% 6.5%, 100% 100%, 0 100%);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: color(#231f20 a(.8));
  }
}
