.title {
  &&_theme_line {
    position: relative;
    padding-bottom: 1em;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 2em;
      height: 6px;
      background-color: var(--color__text);
    }

    &_red {
      &:after {
        background-color: var(--color__red);
      }
    }
  }
}
