.woocommerce {
  .products {
    margin-bottom: 0 !important;

    .type-product {
      margin-bottom: 2.4em !important;
      @media (--phone){
        float: none !important;
        margin-right: 0 !important;
        width: 100% !important;
      }

      .attachment-woocommerce_thumbnail {
        margin-bottom: 0;
      }

      .woocommerce-LoopProduct-link {
        &:hover {
          .product__head {
            background-color: var(--color__text);
          }

          .woocommerce-loop-product__title {
            color: white;

            &:after {
              background-color: white;
            }
          }
        }
      }

      .product__img {
        position: relative;

        &:before,
        &:after {
          position: absolute;
          opacity: 0;
          transition: opacity .5s linear;
        }

        &:before {
          content: '';
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: color(var(--color__text) a(.3));
        }

        &:after {
          content: 'read more';
          top: 50%;
          left: 50%;
          padding: .4em 1.4em;
          border: 1px solid white;
          text-transform: uppercase;
          font: 400 14px/1.2 'Montserrant', sans-serif;
          color: white;
          transform: translate(-50%, -50%);
        }

        &:hover {
          &:after,
          &:before {
            opacity: 1;
          }
        }
      }

      .product__head {
        padding: 1em 0;
        transition: background-color .3s linear;
      }

      .woocommerce-loop-product__title {
        position: relative;
        padding: 6px 1em;
        text-align: left;
        font: 600 1rem/1.2 'Montserrat', sans-serif;
        transition: color .3s linear;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 1em;
          left: 1em;
          height: 1px;
          background-color: var(--color__text);
          transition: background-color .3s linear;
        }
      }

      .button {
        display: none;
      }
    }
  }

  &.post-type-archive,
  &.single-product {
    #primary {
      float: right;
      @media (--phone){
        float: none;
      }
    }
    #secondary {
      float: left;
      @media (--phone){
        float: none;
      }
    }
    @media (--phone){
      .content-wrapper > .row {
        display: flex;
        flex-flow: column wrap;
      }

      #primary {
        padding-bottom: 5em;
      }

      #secondary {
        order: -1;
      }
    }

    .woocommerce-breadcrumb,
    .woocommerce-result-count,
    .woocommerce-ordering {
      display: none;
    }

    .content-wrapper {
      padding-top: 3em;
    }
  }

  &.post-type-archive {
    .archive-title {
      padding-bottom: 1em;
      text-transform: uppercase;
      text-align: center;
      font: 700 1.5rem/1.2 'Montserrat', sans-serif;
      color: var(--color__text);
    }
  }

  &.single-product {
    .content-wrapper {
      padding-left: 1em;
      padding-right: 1em;
    }

    .product__title {
      text-align: center;
      font-size: 1.5rem;
      color: var(--color__text);
    }
  }
}
