.video__title {
  --h: 2em;

  position: relative;
  padding-top: var(--h);
  text-align: center;
  font-size: 1rem;
  font-weight: 600;

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    width: 1px;
    height: var(--h);
    background-color: var(--color__text);
  }
}
