.btn {
  &&_theme_line {
    --w: 5em;

    position: relative;
    margin-right: var(--w);

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: calc(var(--w) * -1);
      width: var(--w);
      height: 1px;
      background-color: var(--color__text);
    }

    &&_red {
      &:after {
        background-color: var(--color__red);
      }
    }
  }
}
