.enquiry {
  --h: 25%;
  @media (--phone){
    --h: 5%;
  }

  @mixin bevelSpace;
  padding-bottom: 12% !important;
  background-color: color( var(--color__gray) a(.5) );
  clip-path: polygon( 0 var(--h), 100% 0, 100% 100%, 0 calc(100% - var(--h)) );
  @media (--phone){
    padding-bottom: 15% !important;
  }
}
