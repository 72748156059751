.wpcf7 {
  input {
    margin-top: 1em;

    &[type="text"],
    &[type="email"],
    &[type="tel"] {
      border-color: var(--color__text);
      background-color: transparent;
      font: 400 1rem/1.2 'Montserrat', sans-serif;
      color: var(--color__text);

      &::placeholder {
        opacity: 1;
        color: var(--color__text);
      }

      &:focus {
        outline: 0;
      }
    }

    &[name="your-name"],
    &[name="your-email"],
    &[name="tel-224"] {
      width: calc(100% / 3 -1em);
      @media (--phone){
        width: 100%;
      }
    }

    &[name="your-email"],
    &[name="tel-224"] {
      margin-left: 1.5em;
      @media (--phone) {
        margin-left: 0;
      }
    }

    &[name="your-message"] {
      width: 100%;
    }

    &[type="reset"],
    &[type="submit"] {
      padding: .4em 2em;
      border-radius: 0;
      font: 400 14px/1.2 'Montserrat', sans-serif;
    }

    &[type="reset"] {
      background-color: transparent;
      color: var(--color__red);

      &:hover {
        border-color: transparent;
      }
    }
  }
}
