.images-wrap {
    &__arrow {
        position: absolute;
        top: 50%;
        width: 15px;
        height: 15px;
        border-top: 1px solid white;
        cursor: pointer;
    }
}

.arrow {
    &_left {
        left: 10%;
        border-left: 1px solid white;
        transform: translateY(-50%) rotate(-45deg);
    }

    &_right {
        right: 10%;
        border-right: 1px solid white;
        transform: translateY(-50%) rotate(45deg);
    }
}
