.wpb_single_image {
  display: flex;
  flex-flow: column nowrap;

  .wpb_heading {
    order: 2;
    margin-top: 1em;
    text-transform: uppercase;
    font: 300 1rem/1.2 'Montserrat', sans-serif;
    color: white;
  }
}
