.video {
  &&_shape {
    --h: 25%;
    @media (--phone){
      --h: 5%;
    }

    @mixin bevelSpace;
    clip-path: polygon( 0 var(--h), 100% 0, 100% 100%, 0 calc(100% - var(--h)) );
  }

  .vc_col-sm-4,
  .vc_col-sm-6 {
    &:hover {
      .video__title {
        color: var(--color__red);

        &:before {
          background-color: var(--color__red);
        }
      }
    }
  }
}
