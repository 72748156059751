#mainnav {
  .menu {
    &-item {
      padding: 0 2em;
      text-transform: uppercase;

      &.current_page_parent,
      &.current-menu-item,
      &.current_page_item {
        > a {
          font-weight: 700;
          color: var(--color__red);
        }
      }
    }
  }
}
