.header-clone {
  display: none;
}
.site-header {
  position: absolute;
  background-color: transparent;
  @media (--phone){
    padding: 5px 0;
  }

  &.fixed {
    position: absolute;
  }
}
