.image-box {
  position: relative;
  z-index: var(--zIndex__bottom);
  padding: 1em 0 0 1em;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 60%;
    bottom: 30%;
    left: 0;
    z-index: -1;
    background-color: var(--color__text);
  }

  &_red {
    &:before {
      background-color: var(--color__red);
    }
  }
}
