.feature {
  --h: 25%;
  @media (--phone){
    --h: 10%;
  }

  @mixin bevelSpace;
  clip-path: polygon(0 0, 100% var(--h), 100% calc(100% - var(--h)), 0 100%);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: color(var(--color__red) a(.9));
  }

  .wpb_single_image {
    margin-top: 2em;
  }
}
