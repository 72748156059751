.images-layout__item {
  padding: 10px;
  width: calc(100% / 3);
  @media (--ipad){
    width: 50%;
  }
  @media (--ipad_mini){
    width: 50%;
  }
  @media (--phone){
    width: 50%;
  }
}
