.contact-enquiry {
  @mixin footerBgAndShape;
  padding: 10% 0 3em;

  .wpcf7 {
    input {
      color: white;

      &::placeholder {
        color: white;
      }

      &[type="text"],
      &[type="email"],
      &[type="tel"],
      &[type="submit"] {
        border-color: white;
      }

      &[type="submit"] {
        background-color: white;
        color: var(--color__red);

        &:hover {
          background-color: transparent;
        }
      }

      &[type="reset"] {
        background-color: var(--color__red);

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}
