.footer-widgets {
  padding: 15% 0 1em;
  margin-top: -10%;
  @media screen and (max-width: 1366px){
    margin-top: -13%;
  }
  @media (--ipad){
    margin-top: -14.5%;
  }
  @media (--ipad_mini){
    margin-top: -15%;
  }
  @media (--phone){
    margin-top: 0;
  }
  @mixin footerBgAndShape;

  .widget-title {
    margin-top: 0;
    padding-bottom: 1em;
    text-transform: lowercase;
    font: italic 700 14px/1.2 'Montserrat', sans-serif;
  }

  address {
    margin-bottom: 0;
  }
  
  .textwidget {
    font-size: .9rem;
  }

  .widget {
    padding-top: 0;
  }
}
