@mixin font Montserrat, montserrat-semibold, 600;
@mixin font Montserrat, montserrat-regular, 400;
@mixin font Montserrat, montserrat-medium, 500;
@mixin font Montserrat, montserrat-light, 300;
@mixin font Montserrat, montserrat-bold, 700;
@font-face {
  font-family: Montserrat;
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/Montserrat.eot'); /* IE9+ */
  src: url('../fonts/Montserrat.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Montserrat.woff') format('woff'), /* chrome、firefox */
  url('../fonts/Montserrat.woff2') format('woff2'), 
  url('../fonts/Montserrat.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url('../fonts/Montserrat.svg#fontname') format('svg'); /* iOS 4.1- */
}
