.widget_products {
  margin-top: .3em;
  @media (--phone){
    margin-top: 0;
  }

  .widget-title {
    padding: .4em 0;
    background-color: var(--color__red);
    text-align: center;
    font: 600 .9rem/1.2 'Montserrat', sans-serif;
    color: white !important;
    @media (--phone){
      font-size: 1.1rem;
    }
  }
  
  .product_list_widget {
    margin-top: 1em !important;
    padding: 0 .5em !important;
    @media (--phone){
      display: none;
    }

    li {
      border-bottom: none !important;
    }
  }

  .attachment-woocommerce_thumbnail {
    display: none;
  }

  .product-title {
    font: 400 16px/1.2 'Montserrat', sans-serif;
    color: var(--color__text);

    &:hover {
      font-weight: 700;
    }
  }
}
@each $i in 77, 76, 75, 74, 72, 65, 186, 189, 194 {
  .postid-$(i) .widget_products .product-$(i) .product-title {
    font-weight: 700;
  }
}
